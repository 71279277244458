import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index.js";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "MainPage",
    component: () =>
      import(
        /* webpackChunkName: "PublicMainPage" */ "@/views/LandingPage/MainPage.vue"
      ),
  },
  {
    path: "/proveedor",
    name: "ProviderHomepage",
    component: () =>
      import(
        /* webpackChunkName: "ProviderHomepage" */ "@/views/Provider/Homepage.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/proveedor/productos",
    name: "ProviderProducts",
    component: () =>
      import(
        /* webpackChunkName: "ProviderProducts" */ "@/views/Provider/Products.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/proveedor/servicios/:id",
    name: "ProviderServicesDetails",
    component: () =>
      import(
        /* webpackChunkName: "ProviderServiceDetails" */ "@/views/Provider/ServicesDetails.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/proveedor/paquetes/:id",
    name: "ProviderPackagesDetails",
    component: () =>
      import(
        /* webpackChunkName: "ProviderPackagesDetails" */ "@/views/Provider/PackagesDetails.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/proveedor/calendario",
    name: "ProviderCalendar",
    component: () =>
      import(
        /* webpackChunkName: "ProviderCalendar" */ "@/views/Provider/Calendar.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/proveedor/rendimiento",
    name: "ProviderPerformance",
    component: () =>
      import(
        /* webpackChunkName: "ProviderPerformance" */ "@/views/Provider/Performance.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/proveedor/perfil",
    name: "ProviderProfile",
    component: () =>
      import(
        /* webpackChunkName: "ProviderProfile" */ "@/views/Provider/Profile.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/proveedor/solicitudes",
    name: "ProviderRequests",
    component: () =>
      import(
        /* webpackChunkName: "ProviderRequests" */ "@/views/Provider/Requests.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/proveedor/solicitudes/:id",
    name: "ProviderRequest",
    component: () =>
      import(
        /* webpackChunkName: "ProviderRequest" */ "@/views/Provider/RequestsDetails.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/proveedor/reservaciones",
    name: "ProviderReservations",
    component: () =>
      import(
        /* webpackChunkName: "ProviderReservations" */ "@/views/Provider/Reservations.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/proveedor/reservaciones/servicios/:id",
    name: "ProviderServiceReservation",
    component: () =>
      import(
        /* webpackChunkName: "ProviderServiceReservation" */ "@/views/Provider/ServiceReservationDetails.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/proveedor/reservaciones/paquetes/:id",
    name: "ProviderPackageReservation",
    component: () =>
      import(
        /* webpackChunkName: "ProviderPackageReservation" */ "@/views/Provider/PackageReservationDetails.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/proveedor/registro",
    name: "RegisterProvider",
    component: () =>
      import(
        /* webpackChunkName: "RegisterProvider" */ "@/views/Provider/Register.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/proveedor/registro/:id/stripe-registro-exitoso",
    name: "SuccessStripeRegistration",
    component: () =>
      import(
        /* webpackChunkName: "SuccessStripeRegistration" */ "@/views/Provider/SuccessStripeRegistration"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/proveedor/registro/producto",
    name: "CreateProduct",
    component: () =>
      import(
        /* webpackChunkName: "CreateProduct" */ "@/views/Provider/CreateProduct.vue"
      ),
    meta: { requiresAuth: true },
  },
  // {
  //   path: "/publico",
  //   name: "MainPage",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "MainPage" */ "@/views/LandingPage/MainPage.vue"
  //     ),
  // },
  {
    path: "/quienes-somos",
    name: "WhoAreWePublic",
    component: () =>
      import(
        /* webpackChunkName: "WhoAreWePublic" */ "@/views/LandingPage/WhoAreWe.vue"
      ),
  },
  {
    path: "/politicas-privacidad",
    name: "TermsOfServicePublic",
    component: () =>
      import(
        /* webpackChunkName: "TermsOfServicePublic" */ "@/views/LandingPage/TermsOfUse.vue"
      ),
  },
  {
    path: "/usuario/quienes-somos",
    name: "WhoAreWeUser",
    component: () =>
      import(
        /* webpackChunkName: "WhoAreWeUser" */ "@/views/User/WhoAreWe.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/usuario/politicas-privacidad",
    name: "TermsOfServiceUser",
    component: () =>
      import(/* webpackChunkName: "MainPage" */ "@/views/User/TermsOfUse.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/ayuda",
    name: "Help",
    component: () =>
      import(/* webpackChunkName: "Help" */ "@/views/LandingPage/Help.vue"),
  },
  {
    path: "/info/proveedores",
    name: "MainPageProviders",
    component: () =>
      import(
        /* webpackChunkName: "MainPageProviders" */ "@/views/LandingPage/MainPageProviders.vue"
      ),
  },
  {
    path: "/proveedores",
    name: "Providers",
    component: () =>
      import(
        /* webpackChunkName: "Providers" */ "@/views/LandingPage/Providers.vue"
      ),
  },
  {
    path: "/proveedor/:id",
    name: "Provider",
    component: () =>
      import(
        /* webpackChunkName: "Provider" */ "@/views/LandingPage/Provider.vue"
      ),
  },
  {
    path: "/servicios",
    name: "Services",
    component: () =>
      import(
        /* webpackChunkName: "Services" */ "@/views/LandingPage/Services.vue"
      ),
  },
  {
    path: "/paquetes",
    name: "Packages",
    component: () =>
      import(
        /* webpackChunkName: "Packages" */ "@/views/LandingPage/Packages.vue"
      ),
  },
  {
    path: "/servicios/:id",
    name: "Service",
    component: () =>
      import(
        /* webpackChunkName: "Service" */ "@/views/LandingPage/Service.vue"
      ),
  },
  {
    path: "/paquete/:id",
    name: "Package",
    component: () =>
      import(
        /* webpackChunkName: "Package" */ "@/views/LandingPage/Package.vue"
      ),
  },
  {
    path: "/usuario",
    name: "UserMainPage",
    component: () =>
      import(
        /* webpackChunkName: "UserMainPage" */ "@/views/User/MainPage.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/usuario/ayuda",
    name: "UserHelp",
    component: () =>
      import(/* webpackChunkName: "UserHelp" */ "@/views/User/Help.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/usuario/proveedores",
    name: "UserProviders",
    component: () =>
      import(
        /* webpackChunkName: "UserProviders" */ "@/views/User/Providers.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/usuario/proveedor/:id",
    name: "UserProvider",
    component: () =>
      import(
        /* webpackChunkName: "UserProvider" */ "@/views/User/Provider.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/usuario/servicios",
    name: "UserServices",
    component: () =>
      import(
        /* webpackChunkName: "UserServices" */ "@/views/User/Services.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/usuario/paquetes",
    name: "UserPackages",
    component: () =>
      import(
        /* webpackChunkName: "UserPackages" */ "@/views/User/Packages.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/usuario/servicio/:id",
    name: "UserService",
    component: () =>
      import(/* webpackChunkName: "UserService" */ "@/views/User/Service.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/usuario/paquete/:id",
    name: "UserPackage",
    component: () =>
      import(/* webpackChunkName: "UserPackage" */ "@/views/User/Package.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/usuario/mis-reservaciones",
    name: "UserReservations",
    component: () =>
      import(
        /* webpackChunkName: "UserReservations" */ "@/views/User/MyReservations.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/usuario/mis-reservaciones/servicios/:id",
    name: "UserReceiptDetails",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "UserReceiptDetails" */ "@/views/User/ReceiptDetails.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/usuario/mis-reservaciones/paquetes/:id",
    name: "UserPackageReceiptDetails",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "UserReceiptDetails" */ "@/views/User/PackageReceiptDetails.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/usuario/mis-solicitudes",
    name: "UserRequests",
    component: () =>
      import(
        /* webpackChunkName: "UserRequests" */ "@/views/User/MyRequests.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/usuario/mis-solicitudes/:id",
    name: "UserRequestsQuotation",
    component: () =>
      import(
        /* webpackChunkName: "UserRequestsQuotation" */ "@/views/User/RequestDetails.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/usuario/pagos/:id",
    name: "UserPayment",
    component: () =>
      import(/* webpackChunkName: "UserPayment" */ "@/views/User/Payment.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/usuario/pagos/paquetes/reservacion/:id",
    name: "UserPackagePayment",
    component: () =>
      import(
        /* webpackChunkName: "UserPackagePayment" */ "@/views/User/PackagePayment.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/usuario/perfil",
    name: "UserProfile",
    component: () =>
      import(/* webpackChunkName: "UserProfile" */ "@/views/User/Profile.vue"),
    meta: { requiresAuth: true },
  },
  // {
  //   path: "/usuario/favoritos",
  //   name: "UserFavorites",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "UserFavorites" */ "@/views/User/Favorites.vue"
  //     ),
  //   meta: { requiresAuth: true },
  // },
  {
    path: "/usuario/pagos",
    name: "UserPayments",
    component: () =>
      import(
        /* webpackChunkName: "UserPayments" */ "@/views/User/Payments.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/usuario/pagos/:qr_id/:payment_id/anticipo_exitoso",
    name: "AdvancePaymentConfirmation",
    component: () =>
      import(
        /* webpackChunkName: "AdvancePaymentConfirmation" */ "@/views/User/AdvancePaymentConfirmation.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/usuario/pagos/paquetes/anticipo_exitoso",
    name: "PackageAdvancePaymentConfirmation",
    component: () =>
      import(
        /* webpackChunkName: "PackageAdvancePaymentConfirmation" */ "@/views/User/AdvancePaymentConfirmation.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/usuario/pagos/:qr_id/:payment_id/liquidacion_exitosa",
    name: "SettlementPaymentConfirmation",
    component: () =>
      import(
        /* webpackChunkName: "SettlementPaymentConfirmation" */ "@/views/User/SettlementPaymentConfirmation.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/usuario/pagos/paquetes/liquidacion_exitosa",
    name: "PackageSettlementPaymentConfirmation",
    component: () =>
      import(
        /* webpackChunkName: "PackageSettlementPaymentConfirmation" */ "@/views/User/SettlementPaymentConfirmation.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/usuario/pagos/:qr_id/:payment_id/abono_exitoso",
    name: "PartialPaymentConfirmation",
    component: () =>
      import(
        /* webpackChunkName: "PartialPaymentConfirmation" */ "@/views/User/PartialPaymentConfirmation.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/usuario/pagos/paquetes/abono_exitoso",
    name: "PackagePartialPaymentConfirmation",
    component: () =>
      import(
        /* webpackChunkName: "PackagePartialPaymentConfirmation" */ "@/views/User/PartialPaymentConfirmation.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/admin",
    name: "AdminHomepage",
    component: () =>
      import(
        /* webpackChunkName: "AdminHomepage" */ "@/views/Admin/Homepage.vue"
      ),
    meta: { requiresAuth: true },
  },

  {
    path: "/admin/solicitudes",
    name: "AdminRequests",
    component: () =>
      import(
        /* webpackChunkName: "AdminRequests" */ "@/views/Admin/Requests.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/admin/cuentas",
    name: "AdminAccounts",
    component: () =>
      import(
        /* webpackChunkName: "AdminAccounts" */ "@/views/Admin/Accounts.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/admin/cuentas/cliente",
    name: "AdminAccountsDetails",
    component: () =>
      import(
        /* webpackChunkName: "AdminAccountsDetails" */ "@/views/Admin/AccountsDetails.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/admin/cuentas/proveedor",
    name: "AdminAccountProvider",
    component: () =>
      import(
        /* webpackChunkName: "AdminAccountProvider" */ "@/views/Admin/ProviderView.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/admin/evaluaciones",
    name: "AdminEvaluations",
    component: () =>
      import(
        /* webpackChunkName: "AdminEvaluations" */ "@/views/Admin/Evaluations.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/admin/atencion_cliente",
    name: "AdminCustomerService",
    component: () =>
      import(
        /* webpackChunkName: "AdminCustomerService" */ "@/views/Admin/CustomerService.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/admin/perfil",
    name: "AdminProfile",
    component: () =>
      import(
        /* webpackChunkName: "AdminProfile" */ "@/views/Admin/Profile.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/admin/reservaciones",
    name: "AdminReservations",
    component: () =>
      import(
        /* webpackChunkName: "AdminReservations" */ "@/views/Admin/Reservations.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/admin/reservaciones/:id",
    name: "AdminReservationDetails",
    component: () =>
      import(
        /* webpackChunkName: "AdminReservationDetails" */ "@/views/Admin/ReservationDetails.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/error404",
    name: "ErrorPage",
    component: () =>
      import(
        /* webpackChunkName: "ErrorPage" */ "@/views/LandingPage/ErrorPage.vue"
      ),
  },
  // {
  //   path: "/usuario/error404",
  //   name: "ErrorPageUser",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "ErrorPageUser" */ "@/views/LandingPage/ErrorPage.vue"
  //     ),
  //   meta: { requiresAuth: true },
  // },
  // {
  //   path: "/proveedor/error404",
  //   name: "ErrorPageProvider",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "ErrorPageProvider" */ "@/views/LandingPage/ErrorPage.vue"
  //     ),
  //   meta: { requiresAuth: true },
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeResolve(async (to, from, next) => {
  // ! Need Login
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    Vue.prototype.$Amplify.Auth.currentAuthenticatedUser()
      .then(async (data) => {
        const isProvider = await store.getters.isProvider;
        let providerPath = to.fullPath.includes("proveedor");
        if (!isProvider && providerPath) {
          if (
            to.name !== "UserProviders" &&
            to.name !== "UserProvider" &&
            to.name !== "SuccessStripeRegistration" &&
            to.name !== "RegisterProvider"
          ) {
            next({ path: "/" });
          }
        } else if (isProvider && providerPath) {
          to.name === "RegisterProvider" && next({ path: "/" });
        }
        if (data && data.signInUserSession) {
          // ! Login User
          next();
        } else {
          // ! Not Login User
          next({ path: "/" });
        }
      })
      .catch(() => {
        // ! Not Login User
        next({ path: "/" });
      });
  } else {
    // ! Public routes
    Vue.prototype.$Amplify.Auth.currentAuthenticatedUser()
      .then((data) => {
        if (data && data.signInUserSession) {
          // ! Login User
          if (to.path === "/error404") {
            next();
          } else {
            next({ path: "/usuario" });
          }
        } else {
          // ! Not Login User
          next();
        }
      })
      .catch(() => {
        // ! Not Login User
        next();
      });
  }
});

export default router;
