import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Amplify, * as AmplifyModules from "aws-amplify";
import { Auth } from "aws-amplify";
import { AmplifyPlugin } from "aws-amplify-vue";
import awsconfig from "./aws-exports";
import VueApollo from "vue-apollo";
import ApolloClient from "apollo-boost";

export const apolloClient = new ApolloClient({
  // ! Local Endpoint
  // uri: "http://localhost:4000/api/graphql",
  uri: process.env.VUE_APP_URI,
  // ! Prod Endpoint
  // uri: "https://api.zaturna.com/api/graphql",

  // ! Config to send headers
  request: (operation) => {
    let token = "";
    if (store.state.cognitoUser) {
      token = store.state.cognitoUser.signInUserSession.accessToken.jwtToken;
    }
    operation.setContext({
      headers: token
        ? {
            Authorization: `${token}`,
          }
        : {},
    });
  },
  onError: ({ networkError, graphQLErrors }) => {
    if (graphQLErrors) {
      const errorUnauthorized = graphQLErrors.filter(
        (error) => error.message === "unauthorized"
      );
      if (errorUnauthorized.length > 0) {
        Auth.signOut();
        router.push("/");
      }
      console.warn("graphQLErrors", graphQLErrors);
    }
    if (networkError) {
      console.warn("networkError", networkError);
    }
  },
});

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

Amplify.configure(awsconfig);

Vue.use(AmplifyPlugin, AmplifyModules);

Vue.use(VueApollo);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  apolloProvider,
  vuetify,
  async created() {
    await this.findUser();
    await this.$store.dispatch("fetchCategories");
    await this.$store.dispatch("fetchPackageTypes");
  },

  methods: {
    async findUser() {
      try {
        const user = await AmplifyModules.Auth.currentAuthenticatedUser();
        await this.$store.dispatch("signedIn", true);
        await this.$store.dispatch("saveCognitoUser", user);
        await this.$store.dispatch("fetchUser");
      } catch (error) {
        await this.$store.dispatch("signedIn", false);
        await this.$store.dispatch("saveUser", null);
        await this.$store.dispatch("saveCognitoUser", null);
        await Auth.signOut();
      }
    },
  },
  render: (h) => h(App),
}).$mount("#app");
