import { fileToBase64 } from "./Utils";
import imageCompression from "browser-image-compression";

/**
 * Compresses and converts n images to base64 objects.
 *
 * Contact the author before making any changes
 * @todo Implement dinamic widthOrHeight
 * @async
 * @param {[File]} images file to be compressed
 * @param {Number} [maxCompressedMBs=0.5] - Maximum number of MBs per image after compressing
 * @returns {[{base64: string, name: string}]} Returns an array of the compressed images converted to base64
 * @throws Will throw an error if the file couldn't be converted correctly
 * @author Giovanni Guzman <giovanni.guzman@ilumps.com>
 */
export const compressImagesAndConvertToBase64 = async (
  images,
  maxCompressedMBs = 0.5
) => {
  let readers = [];
  let result = [];

  images.forEach((img) => {
    readers.push(compressImage(img, maxCompressedMBs));
  });

  const compressedImages = await Promise.all(readers);
  readers = [];
  compressedImages.forEach((img_1) => {
    readers.push(fileToBase64(img_1, false));
  });
  const imagesBase64 = await Promise.all(readers);
  for (let i = 0; i < imagesBase64.length; i++) {
    result.push({
      base64: imagesBase64[i],
      name: images[i].name,
    });
  }

  return result;
};

/**
 * Compresses an image.
 *
 * Contact the author before making any changes
 * @todo Implement dinamic widthOrHeight
 * @async
 * @param {file} file - file to be compressed
 * @param {Number} maxCompressedMB - Maximum number of MBs after compressing
 * @returns {file} - compressed image
 * @throws Will throw an error if the file couldn't be converted correctly
 * @author Giovanni Guzman <giovanni.guzman@ilumps.com>
 */
export const compressImage = async (file, maxCompressedMBs) => {
  // console.log("originalFile instanceof Blob", file instanceof Blob); // true
  // console.log(`originalFile size ${file.size / 1024 / 1024} MB`);

  const options = {
    maxSizeMB: maxCompressedMBs,
    // maxWidthOrHeight: 1024,
    useWebWorker: true,
  };
  try {
    const compressedFile = await imageCompression(file, options);
    // console.log(
    //   "compressedFile instanceof Blob",
    //   compressedFile instanceof Blob
    // );
    // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
    return compressedFile;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
