/**
 * Converts a file to base64.
 *
 * Contact the author before making any changes
 * @param {file} file - file to be converted to base64
 * @param {boolean} [asURL=true] - If set to true, the base64 generated will be a URL. If not, just the base64
 * @returns {Promise} Promise object represents the base64 of the file.
 * @author Giovanni Guzman <giovanni.guzman@ilumps.com>
 */
export const fileToBase64 = (file, asURL = true) => {
  return new Promise(function (resolve, reject) {
    let fr = new FileReader();
    fr.onload = function () {
      if (asURL) {
        resolve(fr.result);
      } else {
        resolve(fr.result.split(",")[1]);
      }
    };
    fr.onerror = function () {
      reject(fr);
    };
    fr.readAsDataURL(file);
  });
};

export const urlToFile = async (url, name, defaultType = "image/jpeg") => {
  const response = await fetch(url);
  const data = await response.blob();
  return new File([data], name, {
    type: data.type || defaultType,
  });
};

export const numberToCurrency = (number) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  }).format(number);
};

/**
 * Formats a provider address.
 * @param {Object} providerAddress - The address of the provider as an object.
 * @param {string} providerAddress.city
 * @param {string} providerAddress.externalNumber
 * @param {string} providerAddress.internalNumber
 * @param {string} providerAddress.state
 * @param {string} providerAddress.street
 * @param {string} providerAddress.zipCode
 * @param {string} providerAddress.district
 * @returns {string} The address of the provider formatted
 */
export const formatProviderAddress = (providerAddress) => {
  return (
    providerAddress.street +
    " #" +
    providerAddress.externalNumber +
    (providerAddress.internalNumber
      ? "-" + providerAddress.internalNumber
      : "") +
    ", " +
    providerAddress.district +
    ", " +
    providerAddress.zipCode +
    ", " +
    providerAddress.city +
    ", " +
    providerAddress.state
  );
};

/**
 * Merges two lists of products. The products must have a date property as a unix timestamp date.
 * @param {Array} listA
 * @param {Array} listB
 * @returns {Array} The merged and ordered ascending list of products.
 */
export const mergeProductsLists = (listA, listB) => {
  const prefixedListA = listA.map((item) => ({
    ...item,
    uniqueId: `A-${item.id}`,
  }));
  const prefixedListB = listB.map((item) => ({
    ...item,
    uniqueId: `B-${item.id}`,
  }));

  const mergedList = [...prefixedListA, ...prefixedListB];

  mergedList.sort((a, b) => b.date - a.date);

  return mergedList;
};
