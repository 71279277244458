import Vue from "vue";
import Vuex from "vuex";
import { GET_PROVIDER, GET_USER, GET_CATEGORIES } from "@/graphql/queries";
import { useQuery } from "@/graphql/index";
import {
  INSERT_PROVIDER,
  INSERT_SERVICE,
  INSERT_PACKAGE,
  UPLOAD_USER_PROFILE_PICTURE,
  UPDATE_PROVIDER,
  UPLOAD_PROVIDER_PROFILE_PICTURE,
  MODIFY_USER_INFO,
  UPLOAD_USER_ID_CARD,
  UPDATE_PROVIDER_WITH_NEW_STRIPE_INFO,
} from "@/graphql/mutations";
import { useMutation } from "@/graphql/index";
import { compressImagesAndConvertToBase64 } from "@/Utils/compressImage";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: ["provider"],
    }),
  ],
  state: {
    cognitoUser: null,
    user: null,
    signedIn: false,
    alert: {
      type: null,
      message: "",
    },
    provider: null,
    categories: [],
    packageTypes: [],
    currentCheckoutSession: null,
  },
  mutations: {
    signedIn(state, payload) {
      state.signedIn = payload;
    },
    saveUser(state, payload) {
      state.user = payload;
    },
    saveCognitoUser(state, payload) {
      state.cognitoUser = payload;
    },
    saveProvider(state, payload) {
      state.provider = payload;
    },
    handleAlert(state, { type, message }) {
      state.alert.type = type;
      state.alert.message = message;
    },
    saveCategories(state, payload) {
      state.categories = payload;
    },
    savePackageTypes(state, payload) {
      state.packageTypes = payload;
    },
    saveCurrentCheckoutSession(state, payload) {
      state.currentCheckoutSession = payload;
    },
  },
  actions: {
    signedIn(context, payload) {
      context.commit("signedIn", payload);
    },
    async fetchCategories({ commit }) {
      const { data, errors } = await useQuery(GET_CATEGORIES, {});
      if (data) {
        commit("saveCategories", data.categories);
      } else if (errors) {
        console.log(errors);
      }
    },
    async fetchPackageTypes({ commit }) {
      commit("savePackageTypes", ["Piñata", "General"]);
    },
    async fetchUser({ commit }) {
      const { data } = await useQuery(GET_USER, {});
      if (data) {
        const provider = data.user.provider;
        delete data.user.provider;
        commit("saveUser", data.user);
        commit("saveProvider", provider);
        commit("signedIn", true);
      } else {
        throw new Error("No hay información para regresar");
      }
    },
    saveUser(context, payload) {
      context.commit("saveUser", payload);
    },
    saveCognitoUser(context, payload) {
      context.commit("saveCognitoUser", payload);
    },
    saveCurrentCheckoutSession(context, payload) {
      context.commit("saveCurrentCheckoutSession", payload);
    },
    async uploadUserPictureProfile(context, payload) {
      let { data, errors } = await useMutation(UPLOAD_USER_PROFILE_PICTURE, {
        file_base64: payload.fileBase64,
        file_name: payload.filename,
      });
      if (data) {
        await context.dispatch("fetchUser");
        await context.dispatch("handleAlert", {
          type: "success",
          message: "Imagen guardada correctamente",
        });
      } else if (errors) {
        console.log(errors);
        await context.dispatch("handleAlert", {
          type: "error",
          message: "Error al subir imagen",
        });
      }
    },

    async updateUserCardId(context, payload) {
      let { data, errors } = await useMutation(UPLOAD_USER_ID_CARD, {
        userId: parseInt(context.state.user.id),
        fileBase64: payload.fileBase64,
        fileName: payload.filename,
      });
      if (data) {
        const user = { ...context.state.user, idCard: data.updateUserIdCard };
        context.commit("saveUser", user);
        await context.dispatch("handleAlert", {
          type: "success",
          message: "Archivo guardado correctamente",
        });
      } else if (errors) {
        await context.dispatch("handleAlert", {
          type: "error",
          message: "Error al subir archivo",
        });
      }
    },

    async updateUserInfo(context, payload) {
      let tempUser = { ...payload };
      tempUser.userId = parseInt(payload.id);
      let { data, errors } = await useMutation(MODIFY_USER_INFO, tempUser);
      if (data) {
        // console.log(data);
        await context.dispatch("fetchUser");
        await context.dispatch("handleAlert", {
          type: "success",
          message: "Los cambios se han guardado correctamente.",
        });
      } else if (errors) {
        console.log(errors);
        await context.dispatch("handleAlert", {
          type: "error",
          message: "Error al dar de alta datos.",
        });
      }
    },

    handleAlert(context, payload) {
      context.commit("handleAlert", payload);
    },

    // Payload = {provider, idCard}
    async createProvider(context, payload) {
      const compressedProfilePicture = await compressImagesAndConvertToBase64(
        payload.provider.profilePicture
      );

      const compressedIdCard = await compressImagesAndConvertToBase64(
        payload.idCard
      );

      const compressedImages = await compressImagesAndConvertToBase64(
        payload.provider.providerImages.images
      );

      const internalNumber = payload.provider.internalNumber
        ? payload.provider.internalNumber.trim()
        : payload.provider.internalNumber;

      const { data, errors } = await useMutation(INSERT_PROVIDER, {
        userId: context.state.user.id,
        name: payload.provider.workName.trim(),
        phone: payload.provider.phoneNum,
        email: payload.provider.email,
        rfc: payload.provider.rfc,
        state: payload.provider.stateOriginWork,
        city: payload.provider.cityOriginWork,
        zipCode: payload.provider.postalCode,
        street: payload.provider.address.trim(),
        district: payload.provider.blockName.trim(),
        externalNumber: payload.provider.externalNumber,
        internalNumber: internalNumber,
        idCardBase64: compressedIdCard[0].base64,
        idCardName: compressedIdCard[0].name,
        profilePicBase64: compressedProfilePicture[0].base64,
        profilePicName: compressedProfilePicture[0].name,
        description: payload.provider.description.trim(),
        instagram: payload.provider.instagramPage.trim(),
        facebook: payload.provider.facebookPage.trim(),
        stripeConnectedAccount: payload.provider.stripeConnectedAccount,
        providerImages: compressedImages,
      });
      if (data) {
        context.commit("saveProvider", data.createProvider);
        context.dispatch("handleAlert", {
          type: "success",
          message: "Proveedor creado correctamente",
        });
      } else if (errors) {
        console.log(errors);
        context.dispatch("handleAlert", {
          type: "error",
          message: "Error al crear proveedor",
        });
      }
      return data;
    },
    async updateProvider(context, payload) {
      let tempProvider = { ...payload };
      tempProvider.providerId = parseInt(payload.id);

      const internalNumber = tempProvider.internalNumber
        ? tempProvider.internalNumber.trim()
        : tempProvider.internalNumber;

      const { data, errors } = await useMutation(UPDATE_PROVIDER, {
        providerId: tempProvider.providerId,
        name: tempProvider.name.trim(),
        phone: tempProvider.phone,
        email: tempProvider.email,
        rfc: tempProvider.rfc,
        state: tempProvider.state,
        city: tempProvider.city,
        zipCode: tempProvider.zipCode,
        street: tempProvider.street.trim(),
        district: tempProvider.district.trim(),
        externalNumber: tempProvider.externalNumber,
        description: tempProvider.description.trim(),
        instagram: tempProvider.instagram.trim(),
        facebook: tempProvider.facebook.trim(),
        internalNumber: internalNumber,
        providerImages: tempProvider.images,
      });
      if (data) {
        context.commit("saveProvider", data.updateProvider);
        context.dispatch("handleAlert", {
          type: "success",
          message: "La información del perfil ha sido editada correctamente",
        });
      } else if (errors) {
        console.log(errors);
        context.dispatch("handleAlert", {
          type: "error",
          message: "Error al editar información del proveedor",
        });
      }
      return data;
    },
    async retrieveProviderStripeInformation(context, payload) {
      const { data, errors } = await useMutation(
        UPDATE_PROVIDER_WITH_NEW_STRIPE_INFO,
        { providerId: payload.providerId }
      );
      if (data) {
        let provider = {
          ...context.state.provider,
          stripeMail: data.updateProviderStripeInfo.stripeMail,
          stripeLastFourDigits:
            data.updateProviderStripeInfo.stripeLastFourDigits,
          stripeChargeEnable: data.updateProviderStripeInfo.stripeChargeEnable,
          stripeConnectedAccount:
            data.updateProviderStripeInfo.stripeConnectedAccount,
        };
        context.commit("saveProvider", provider);
      } else if (errors) {
        console.log(errors);
        context.dispatch("handleAlert", {
          type: "error",
          message:
            "Error al acceder a la información del perfil de Stripe del proveedor",
        });
      }
    },

    async fetchProvider(context) {
      const providerId = parseInt(context.state.provider.id);
      const { data, errors } = await useQuery(GET_PROVIDER, {
        id: providerId,
      });
      if (data) {
        context.commit("saveProvider", data.provider);
      } else if (errors) {
        console.log(errors);
        context.dispatch("handleAlert", {
          type: "error",
          message: "Error al acceder a la información del proveedor",
        });
      }
    },

    async updateProviderPictureProfile(context, payload) {
      let { data, errors } = await useMutation(
        UPLOAD_PROVIDER_PROFILE_PICTURE,
        {
          file_base64: payload.fileBase64,
          file_name: payload.filename,
          provider_id: context.state.provider.id,
        }
      );
      if (data) {
        let provider = {
          ...context.state.provider,
          photoUrl: data.updateProviderProfileImage.photoUrl,
        };
        context.commit("saveProvider", provider);
        await context.dispatch("handleAlert", {
          type: "success",
          message: "Imagen guardada correctamente",
        });
      } else if (errors) {
        await context.dispatch("handleAlert", {
          type: "error",
          message: "Error al subir imagen",
        });
      }
    },

    /***
     * payload = {serviceInfo}
     */
    async createService(context, payload) {
      let serviceInfo = payload.serviceInfo;
      let tags = [];

      serviceInfo.tagsList.forEach((thisTag) => {
        tags.push({ tag: thisTag });
      });

      const compressedImages = await compressImagesAndConvertToBase64(
        serviceInfo.images.images
      );

      let gqlService = {
        providerId: parseInt(context.state.provider.id),
        name: serviceInfo.serviceName,
        city: serviceInfo.city,
        state: serviceInfo.state,
        category: serviceInfo.serviceCategory,
        minPeople: parseInt(serviceInfo.minNumPersons),
        maxPeople: parseInt(serviceInfo.maxNumPersons),
        paymentDeadline: serviceInfo.paymentDeadline,
        paymentAdvance: parseInt(serviceInfo.paymentAdvance),
        allCities: serviceInfo.allCities,
        cancellationDeadline: serviceInfo.refundInfo[0].days,
        reimbursement: parseInt(serviceInfo.refundInfo[0].discount),
        partyPlanner: serviceInfo.workWithOrganizers,
        priceRange: serviceInfo.priceRange,
        serviceImages: compressedImages,
        serviceTag: tags,
        description: serviceInfo.description,
      };

      const { data, errors } = await useMutation(INSERT_SERVICE, gqlService);

      if (data) {
        context.dispatch("handleAlert", {
          type: "success",
          message: "Servicio creado correctamente",
        });
      } else if (errors) {
        console.log(errors);
        context.dispatch("handleAlert", {
          type: "error",
          message: "Error al crear servicio",
        });
      }
      return data;
    },

    /***
     * payload = {packageInfo}
     */
    async createPackage(context, payload) {
      const packageInfo = payload.packageInfo;

      const daysOfWeek = {
        L: "monday",
        M: "tuesday",
        Mi: "wednesday",
        J: "thursday",
        V: "friday",
        S: "saturday",
        D: "sunday",
      };

      const prices = packageInfo.prices.reduce((pricesAcc, price) => {
        const filteredPrices = price.daysOfWeek
          .filter((dayOfWeek) => dayOfWeek.value && price.price)
          .map((dayOfWeek) => ({
            dayOfWeek: daysOfWeek[dayOfWeek.name],
            price: parseInt(price.price * 100),
          }));
        return pricesAcc.concat(filteredPrices);
      }, []);

      const schedule = packageInfo.schedule
        .filter(
          (schedule) =>
            /^(0?[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(schedule.timeStart) &&
            /^(0?[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(schedule.timeEnd)
        )
        .slice()
        .sort((a, b) => {
          const [startHourA, startMinuteA] = a.timeStart.split(":").map(Number);
          const startDateA = new Date();
          const timeStartA = startDateA.setHours(
            startHourA,
            startMinuteA,
            0,
            0
          );

          const [startHourB, startMinuteB] = b.timeStart.split(":").map(Number);
          const startDateB = new Date();
          const timeStartB = startDateB.setHours(
            startHourB,
            startMinuteB,
            0,
            0
          );

          return timeStartA - timeStartB;
        });

      const items = packageInfo.items
        .filter(({ quantity, name }) => quantity && name)
        .map(({ quantity, name }) => ({
          quantity: parseInt(quantity),
          name,
        }));

      const supplementaryItems = packageInfo.supplementaryItems
        .filter(({ name, unit, price }) => name && unit && price)
        .map(({ name, unit, price }) => ({
          name,
          unit,
          price: parseInt(price * 100),
        }));

      const dishes = [
        ...packageInfo.dishes.adult
          .filter((dish) => dish.name)
          .map((dish) => ({
            ...dish,
            isForAdult: true,
          })),
        ...packageInfo.dishes.kid
          .filter((dish) => dish.name)
          .map((dish) => ({
            ...dish,
            isForAdult: false,
          })),
      ];

      const images = await compressImagesAndConvertToBase64(
        packageInfo.images.images
      );

      let gqlPackageInfo = {
        providerId: parseInt(context.state.provider.id),
        name: packageInfo.name,
        state: packageInfo.state,
        city: packageInfo.city,
        address: packageInfo.address,
        type: packageInfo.type,
        corkage: packageInfo.corkage,
        corkagePrice: parseInt(packageInfo.corkagePrice * 100),
        adultsQuantity: parseInt(packageInfo.adultsQuantity) || 0,
        kidsQuantity: parseInt(packageInfo.kidsQuantity) || 0,
        capacity:
          (parseInt(packageInfo.adultsQuantity) || 0) +
          (parseInt(packageInfo.kidsQuantity) || 0),
        description: packageInfo.description,
        paymentDeadline: parseInt(packageInfo.paymentDeadline),
        deletedAt: packageInfo.deletedAt,
        cancellationDeadline: parseInt(packageInfo.cancellationDeadline),
        reimbursement: parseInt(packageInfo.reimbursement),
        paymentAdvance: parseInt(packageInfo.paymentAdvance * 100),
        extraCapacity: parseInt(packageInfo.extraCapacity) || 0,
        adultExtraPrice: parseInt(packageInfo.extraCapacityPrice * 100),
        kidExtraPrice: parseInt(packageInfo.extraCapacityPrice * 100),
        extraHour: packageInfo.extraHour,
        extraHourPrice: parseInt(packageInfo.extraHourPrice * 100),
        packagePrices: prices,
        packageSchedule: schedule,
        packageItems: items,
        packageDishes: dishes,
        packageSupplementaryItems: supplementaryItems,
        packageImages: images,
      };

      const { data, errors } = await useMutation(
        INSERT_PACKAGE,
        gqlPackageInfo
      );

      if (data) {
        context.dispatch("handleAlert", {
          type: "success",
          message: "Paquete creado correctamente",
        });
      } else if (errors) {
        console.log(errors);
        context.dispatch("handleAlert", {
          type: "error",
          message: "Error al crear paquete",
        });
      }
      return data;
    },
  },
  getters: {
    isProvider(state) {
      return state.provider;
    },
  },
});

export default store;
