import gql from "graphql-tag";

// FAQ MUTATIONS

export const CREATE_QUESTION = gql`
  mutation createQuestion(
    $question: String!
    $answer: String!
    $type: String!
  ) {
    createZaturnaQuestion(question: $question, answer: $answer, type: $type) {
      id
      question
      answer
      type
    }
  }
`;

// CATEGORY MUTATIONS

export const CREATE_CATEGORY = gql`
  mutation createCategory($name: String!, $favorite: Boolean) {
    createCategory(name: $name, favorite: $favorite) {
      id
      name
      favorite
    }
  }
`;

// USER MUTATIONS

export const INSERT_USER = gql`
  mutation insertUser(
    $name: String!
    $preferedName: String
    $email: String!
    $phone: String!
    $state: String
    $city: String!
  ) {
    createUser(
      name: $name
      preferedName: $preferedName
      email: $email
      phone: $phone
      state: $state
      city: $city
    ) {
      name
      preferedName
      email
      phone
      state
      city
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser(
    $userId: Int!
    $address: String
    $city: String
    $idCard: String
    $name: String
    $phone: String
    $state: String
    $zipCode: String
    $description: String
    $userName: String
  ) {
    updateUser(
      userId: $userId
      address: $address
      city: $city
      idCard: $idCard
      name: $name
      phone: $phone
      state: $state
      zipCode: $zipCode
      description: $description
      userName: $userName
    ) {
      address
      city
      email
      id
      name
      phone
      photo
      state
      zipCode
      idCard
      description
      userName
    }
  }
`;

export const UPLOAD_FILE = gql`
  mutation uploadFile($file_base64: String, $file_name: String!) {
    upload_file(file_base64: $file_base64, file_name: $file_name)
  }
`;

export const UPLOAD_USER_PROFILE_PICTURE = gql`
  mutation uploadUserProfilePicture(
    $file_base64: String!
    $file_name: String!
  ) {
    update_user_picture(file_base64: $file_base64, file_name: $file_name)
  }
`;

export const UPLOAD_PROVIDER_PROFILE_PICTURE = gql`
  mutation uploadProviderProfilePicture(
    $file_base64: String!
    $file_name: String!
    $provider_id: ID!
  ) {
    updateProviderProfileImage(
      fileBase64: $file_base64
      fileName: $file_name
      providerId: $provider_id
    ) {
      photoUrl
    }
  }
`;

export const UPLOAD_USER_ID_CARD = gql`
  mutation uploadUserIdCard(
    $userId: Int!
    $fileBase64: String!
    $fileName: String!
  ) {
    updateUserIdCard(
      user_id: $userId
      file_base64: $fileBase64
      file_name: $fileName
    )
  }
`;

export const MODIFY_USER_INFO = gql`
  mutation modifyUserInfo(
    $address: String
    $city: String
    $name: String
    $phone: String
    $state: String
    $zipCode: String
    $userId: Int!
    $description: String
    $preferedName: String
  ) {
    updateUser(
      address: $address
      city: $city
      name: $name
      phone: $phone
      state: $state
      zipCode: $zipCode
      userId: $userId
      preferedName: $preferedName
      description: $description
    ) {
      name
    }
  }
`;

// PROVIDER MUTATIONS

export const INSERT_PROVIDER = gql`
  mutation insertProvider(
    $userId: ID!
    $name: String!
    $phone: String!
    $email: String!
    $rfc: String!
    $state: String!
    $city: String!
    $zipCode: String!
    $street: String!
    $district: String!
    $externalNumber: String!
    $internalNumber: String
    $idCardBase64: String!
    $idCardName: String!
    $profilePicBase64: String!
    $profilePicName: String!
    $description: String!
    $instagram: String
    $facebook: String
    $providerImages: [InputFile]!
  ) {
    createProvider(
      userId: $userId
      name: $name
      phone: $phone
      email: $email
      rfc: $rfc
      state: $state
      city: $city
      zipCode: $zipCode
      street: $street
      district: $district
      externalNumber: $externalNumber
      internalNumber: $internalNumber
      idCardBase64: $idCardBase64
      idCardName: $idCardName
      profilePicBase64: $profilePicBase64
      profilePicName: $profilePicName
      description: $description
      instagram: $instagram
      facebook: $facebook
      providerImages: $providerImages
    ) {
      id
      userId
      name
      phone
      email
      rfc
      state
      city
      street
      zipCode
      photoUrl
      street
      district
      externalNumber
      internalNumber
      description
      instagram
      facebook
      providerImage {
        url
      }
      stripeConnectedAccount
    }
  }
`;

export const UPDATE_PROVIDER = gql`
  mutation updateProvider(
    $providerId: ID!
    $name: String!
    $phone: String!
    $email: String!
    $rfc: String!
    $state: String!
    $city: String!
    $zipCode: String!
    $street: String!
    $district: String!
    $externalNumber: String!
    $internalNumber: String
    $description: String
    $instagram: String
    $facebook: String
    $providerImages: [InputFile]
  ) {
    updateProvider(
      providerId: $providerId
      name: $name
      phone: $phone
      email: $email
      rfc: $rfc
      state: $state
      city: $city
      zipCode: $zipCode
      street: $street
      district: $district
      externalNumber: $externalNumber
      internalNumber: $internalNumber
      description: $description
      instagram: $instagram
      facebook: $facebook
      providerImages: $providerImages
    ) {
      id
      name
      phone
      email
      rfc
      state
      city
      street
      zipCode
      photoUrl
      street
      district
      externalNumber
      internalNumber
      description
      instagram
      facebook
      providerImage {
        url
      }
    }
  }
`;

export const UPDATE_PROVIDER_WITH_NEW_STRIPE_INFO = gql`
  mutation getNewstripeInfo($providerId: ID!) {
    updateProviderStripeInfo(providerId: $providerId) {
      stripeMail
      stripeChargeEnable
      stripeLastFourDigits
      stripePayoutEnable
      stripeConnectedAccount
    }
  }
`;

// SERVICE MUTATIONS

export const INSERT_SERVICE = gql`
  mutation createService(
    $allCities: Boolean!
    $cancellationDeadline: String!
    $category: String!
    $city: String!
    $description: String
    $maxPeople: Int!
    $minPeople: Int!
    $name: String!
    $partyPlanner: Boolean!
    $paymentAdvance: Int!
    $paymentDeadline: String!
    $priceRange: Int!
    $providerId: Int!
    $reimbursement: Int!
    $serviceImages: [InputServiceImage]
    $serviceTag: [InputServiceTag]
    $state: String!
  ) {
    createService(
      allCities: $allCities
      cancellationDeadline: $cancellationDeadline
      category: $category
      city: $city
      description: $description
      maxPeople: $maxPeople
      minPeople: $minPeople
      name: $name
      partyPlanner: $partyPlanner
      paymentAdvance: $paymentAdvance
      paymentDeadline: $paymentDeadline
      priceRange: $priceRange
      providerId: $providerId
      reimbursement: $reimbursement
      serviceImages: $serviceImages
      serviceTag: $serviceTag
      state: $state
    ) {
      id
      allCities
      cancellationDeadline
      category
      city
      description
      maxPeople
      minPeople
      name
      partyPlanner
      paymentAdvance
      paymentDeadline
      priceRange
      providerId
      reimbursement
      serviceImage {
        url
      }
      serviceTag {
        tag
      }
      state
    }
  }
`;

export const REJECT_QUOTATION_REQUEST = gql`
  mutation rejectQuotationRequest(
    $quotationRequestId: Int!
    $rejectMotive: String!
    $providerComment: String
  ) {
    rejectQuotationRequest(
      quotationRequestId: $quotationRequestId
      rejectMotive: $rejectMotive
      providerComment: $providerComment
    ) {
      id
      providerComment
      rejectedRequest {
        rejectMotive
      }
    }
  }
`;

export const UPDATE_SERVICE = gql`
  mutation updateService(
    $providerId: Int!
    $allCities: Boolean!
    $cancellationDeadline: String!
    $category: String!
    $city: String!
    $description: String
    $maxPeople: Int!
    $minPeople: Int!
    $name: String!
    $partyPlanner: Boolean!
    $paymentAdvance: Int!
    $paymentDeadline: String!
    $priceRange: Int!
    $reimbursement: Int!
    $serviceComment: [InputServiceComment]
    $serviceId: Int!
    $serviceImages: [InputServiceImage]
    $serviceQuestion: [InputServiceQuestion]
    $serviceTag: [InputServiceTag]
    $state: String!
  ) {
    updateService(
      providerId: $providerId
      allCities: $allCities
      cancellationDeadline: $cancellationDeadline
      category: $category
      city: $city
      description: $description
      maxPeople: $maxPeople
      minPeople: $minPeople
      name: $name
      partyPlanner: $partyPlanner
      paymentAdvance: $paymentAdvance
      paymentDeadline: $paymentDeadline
      priceRange: $priceRange
      serviceId: $serviceId
      serviceComment: $serviceComment
      reimbursement: $reimbursement
      serviceImages: $serviceImages
      serviceQuestion: $serviceQuestion
      serviceTag: $serviceTag
      state: $state
    ) {
      id
      allCities
      cancellationDeadline
      category
      city
      description
      maxPeople
      minPeople
      name
      partyPlanner
      paymentAdvance
      paymentDeadline
      priceRange
      providerId
      reimbursement
      serviceImage {
        url
      }
      serviceTag {
        tag
      }
      state
    }
  }
`;

// EVENT MUTATIONS

export const CREATE_EVENT = gql`
  mutation createEvent(
    $state: String!
    $city: String!
    $date: String!
    $serviceId: Int!
    $userId: Int!
    $quotationRequest: InputQuotationRequest!
  ) {
    createEvent(
      state: $state
      city: $city
      date: $date
      serviceId: $serviceId
      userId: $userId
      quotationRequest: $quotationRequest
    ) {
      id
      userId
      date
      city
      eventService {
        id
        eventId
        serviceId
        quotationRequest {
          requesterCity
          requesterName
          requesterEmail
          requesterPhone
          requesterState
          clientComment
          category
          eventType
          eventDate
          eventCity
          eventState
          eventAddress
          peopleQuantity
          paymentAdvance
          status
        }
      }
    }
  }
`;

// QUOTATION REQUEST MUTATIONS

// todavia se le pueden agregar mas campos para updatear conforme se vayan necesitando
export const UPDATE_QUOTATION_REQUEST = gql`
  mutation updateQuotationRequest(
    $quotationRequestId: Int!
    $status: String
    $statusUpdated: String
    $eventState: String
    $eventCity: String
    $eventAddress: String
    $eventDate: String
    $eventType: String
    $peopleQuantity: Int
    $clientComment: String
    $validUntil: String
  ) {
    updateQuotationRequest(
      quotationRequestId: $quotationRequestId
      status: $status
      statusUpdated: $statusUpdated
      eventState: $eventState
      eventCity: $eventCity
      eventAddress: $eventAddress
      eventDate: $eventDate
      eventType: $eventType
      peopleQuantity: $peopleQuantity
      clientComment: $clientComment
      validUntil: $validUntil
    ) {
      id
      status
      statusUpdated
      category
      eventType
      requesterCity
      requesterName
      requesterEmail
      requesterState
      requesterPhone
      status
      eventCity
      clientComment
      eventDate
      peopleQuantity
      eventAddress
      providerComment
      totalPayment
      quotationUrl
      contractUrl
      validUntil
    }
  }
`;

export const ACCEPT_QUOTATION_REQUEST = gql`
  mutation acceptQuotationRequest(
    $quotationRequestId: Int!
    $paymentProvider: Float!
    $paymentZaturna: Float!
    $providerComment: String
    $totalPayment: Float!
    $validUntil: String!
    $quotation: InputFile!
    $extraDocuments: [InputFile]
  ) {
    acceptQuotationRequest(
      quotationRequestId: $quotationRequestId
      paymentProvider: $paymentProvider
      paymentZaturna: $paymentZaturna
      providerComment: $providerComment
      totalPayment: $totalPayment
      validUntil: $validUntil
      quotation: $quotation
      extraDocuments: $extraDocuments
    ) {
      paymentProvider
      paymentZaturna
      providerComment
      totalPayment
      validUntil
      quotationUrl
    }
  }
`;

//RESERVATION AND PAYMENT MUTATIONS

export const CREATE_PAYMENT = gql`
  mutation createPayment($quotationRequestId: Int!, $total: Float!) {
    createPayment(quotationRequestId: $quotationRequestId, total: $total) {
      id
      status
      payment {
        id
        paid
        total
      }
      totalPaid
    }
  }
`;

export const UPDATE_PAYMENT = gql`
  mutation updatePayment($paymentId: Int!, $checkoutSessionId: String) {
    updatePayment(
      paymentId: $paymentId
      checkoutSessionId: $checkoutSessionId
    ) {
      checkoutSessionId
      id
    }
  }
`;

export const CONFIRM_PAYMENT = gql`
  mutation confirmPayment(
    $paymentId: Int!
    $quotationRequestId: Int!
    $userId: Int!
  ) {
    confirmPayment(
      paymentId: $paymentId
      quotationRequestId: $quotationRequestId
      userId: $userId
    ) {
      status
      payment {
        paid
        total
      }
      totalPaid
    }
  }
`;

// PACKAGE MUTATIONS

export const INSERT_PACKAGE = gql`
  mutation createPackage(
    $providerId: Int!
    $name: String!
    $state: String!
    $city: String!
    $address: String!
    $type: String!
    $corkage: Boolean!
    $corkagePrice: Int!
    $adultsQuantity: Int!
    $kidsQuantity: Int!
    $capacity: Int!
    $description: String!
    $paymentDeadline: Int!
    $cancellationDeadline: Int!
    $reimbursement: Int!
    $paymentAdvance: Int!
    $extraCapacity: Int!
    $adultExtraPrice: Int!
    $kidExtraPrice: Int!
    $extraHour: Boolean!
    $extraHourPrice: Int!
    $deletedAt: String
    $packagePrices: [InputPackagePrice]!
    $packageSchedule: [InputPackageSchedule]!
    $packageItems: [InputPackageItem]!
    $packageDishes: [InputPackageDish]!
    $packageSupplementaryItems: [InputPackageSupplementaryItem]
    $packageImages: [InputPackageImage]!
  ) {
    createPackage(
      providerId: $providerId
      name: $name
      state: $state
      city: $city
      address: $address
      type: $type
      corkage: $corkage
      corkagePrice: $corkagePrice
      adultsQuantity: $adultsQuantity
      kidsQuantity: $kidsQuantity
      capacity: $capacity
      description: $description
      paymentDeadline: $paymentDeadline
      cancellationDeadline: $cancellationDeadline
      reimbursement: $reimbursement
      paymentAdvance: $paymentAdvance
      extraCapacity: $extraCapacity
      adultExtraPrice: $adultExtraPrice
      kidExtraPrice: $kidExtraPrice
      extraHour: $extraHour
      extraHourPrice: $extraHourPrice
      deletedAt: $deletedAt
      packagePrices: $packagePrices
      packageSchedule: $packageSchedule
      packageItems: $packageItems
      packageDishes: $packageDishes
      packageSupplementaryItems: $packageSupplementaryItems
      packageImages: $packageImages
    ) {
      id
    }
  }
`;

// PACKAGE RESERVATION MUTATIONS

export const CREATE_PACKAGE_RESERVATION = gql`
  mutation createPackageReservation(
    $packageId: Int!
    $additionalComment: String!
    $cancellationDeadline: String!
    $corkage: Boolean!
    $eventDate: String!
    $eventEndTime: String!
    $eventName: String!
    $eventStartTime: String!
    $extraAdults: Int!
    $extraHours: Int!
    $extraKids: Int!
    $packageAddress: String!
    $packageAdultsQuantity: Int!
    $packagePriceId: Int!
    $packageCity: String!
    $packageKidsQuantity: Int!
    $packageName: String!
    $packageState: String!
    $paymentDeadline: String!
    $reimbursement: Int!
    $reservationDishes: [InputReservationDish]
    $reservationItems: [InputReservationItem]
    $reservationSupplementaryItems: [InputReservationSupplementaryItem]
    $userId: Int!
  ) {
    createPackageReservation(
      additionalComment: $additionalComment
      cancellationDeadline: $cancellationDeadline
      corkage: $corkage
      eventDate: $eventDate
      eventEndTime: $eventEndTime
      eventName: $eventName
      eventStartTime: $eventStartTime
      extraAdults: $extraAdults
      extraHours: $extraHours
      extraKids: $extraKids
      packageAddress: $packageAddress
      packageAdultsQuantity: $packageAdultsQuantity
      packagePriceId: $packagePriceId
      packageCity: $packageCity
      packageId: $packageId
      packageKidsQuantity: $packageKidsQuantity
      packageName: $packageName
      packageState: $packageState
      paymentDeadline: $paymentDeadline
      reimbursement: $reimbursement
      reservationDishes: $reservationDishes
      reservationItems: $reservationItems
      reservationSupplementaryItems: $reservationSupplementaryItems
      userId: $userId
    ) {
      id
      status
      userEmail
      calendarEvent {
        id
        name
      }
      reservationPayments {
        id
        total
        packageReservationId
        paid
        date
      }
    }
  }
`;

export const CREATE_CHECKOUT_SESSION = gql`
  mutation createCheckoutSession(
    $cancelUrl: String!
    $customerEmail: String!
    $paymentId: Int!
    $paymentMethod: String!
    $successUrl: String!
  ) {
    createCheckoutSessionPackageReservationPayment(
      cancelUrl: $cancelUrl
      customerEmail: $customerEmail
      paymentId: $paymentId
      paymentMethod: $paymentMethod
      successUrl: $successUrl
    ) {
      id
      url
      connectedAccount
    }
  }
`;

export const CREATE_PACKAGE_RESERVATION_PAYMENT = gql`
  mutation createPackageReservationPayment(
    $packageReservationId: Int!
    $total: Int!
  ) {
    createPackageReservationPayment(
      packageReservationId: $packageReservationId
      total: $total
    ) {
      checkoutSessionId
      date
      id
      packageReservationId
      paid
      total
    }
  }
`;

// CALENDAR EVENT MUTATIONS

export const CREATE_CALENDAR_EVENT = gql`
  mutation createCalendarEvent(
    $providerId: Int!
    $name: String!
    $clientName: String!
    $startTime: String!
    $endTime: String!
    $isExternalEvent: Boolean!
    $packageId: Int
    $serviceId: Int
  ) {
    createCalendarEvent(
      providerId: $providerId
      name: $name
      clientName: $clientName
      startTime: $startTime
      endTime: $endTime
      isExternalEvent: $isExternalEvent
      packageId: $packageId
      serviceId: $serviceId
    ) {
      id
      providerId
      name
      clientName
      startTime
      endTime
      isExternalEvent
      serviceId
      packageId
      packageReservationId
    }
  }
`;
