<template>
  <v-app>
    <Alert />
    <v-main>
      <router-view :key="$route.fullPath" />
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import Alert from "@/components/Shared/Alerts.vue";
export default {
  name: "App",
  components: {
    Alert,
  },
  data: () => ({
    //
  }),
  computed: mapState({
    username(state) {
      return state.user.name;
    },
  }),
};
</script>

<style lang="scss">
@import "styles/variables";
</style>
