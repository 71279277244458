import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "@fortawesome/fontawesome-free/css/all.css";

Vue.use(Vuetify);
export default new Vuetify({
  icons: {
    iconfont: "fa",
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#622582",
        secondary: "#21145F",
        tertiary: "#244c9a",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        descent: "#808080",
      },
    },
  },
});
