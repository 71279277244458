import gql from "graphql-tag";

// USER QUERIES

export const GET_USER = gql`
  query getUser {
    user {
      address
      city
      email
      id
      name
      phone
      photo
      state
      zipCode
      idCard
      description
      preferedName
      provider {
        id
        userId
        name
        phone
        email
        rfc
        state
        city
        street
        zipCode
        photoUrl
        street
        district
        externalNumber
        internalNumber
        description
        instagram
        facebook
      }
    }
  }
`;

// PROVIDER QUERIES

export const GET_PROVIDER = gql`
  query getProvider($id: Int!) {
    provider(id: $id) {
      id
      userId
      name
      phone
      email
      rfc
      state
      city
      street
      zipCode
      photoUrl
      street
      district
      externalNumber
      internalNumber
      description
      instagram
      facebook
      stripeMail
      stripeChargeEnable
      stripeLastFourDigits
      stripePayoutEnable
      stripeConnectedAccount
    }
  }
`;

export const GET_PROVIDER_COMPLETE = gql`
  query getProvider($id: Int!) {
    provider(id: $id) {
      id
      name
      state
      city
      street
      photoUrl
      district
      description
      insertedAt
      zipCode
      externalNumber
      internalNumber
      stripeMail
      stripeChargeEnable
      stripeLastFourDigits
      stripePayoutEnable
      stripeConnectedAccount
      providerImage {
        providerId
        url
      }
      providerComment {
        score
      }
      service {
        id
        name
        description
        partyPlanner
        allCities
        insertedAt
        category
        serviceComment {
          score
        }
        serviceImage {
          url
        }
      }
    }
    providerPackages(providerId: $id) {
      providerId
      name
      id
      insertedAt
      type
      packageImages {
        url
      }
    }
  }
`;

export const GET_PROVIDER_IMAGES = gql`
  query getProviderImages($providerId: Int!) {
    providerImages(providerId: $providerId) {
      base64
      name
    }
  }
`;

export const GET_PROVIDER_WITH_SERVICES = gql`
  query getProvider($id: Int!) {
    provider(id: $id) {
      id
      name
      state
      city
      street
      photoUrl
      district
      description
      insertedAt
      zipCode
      externalNumber
      stripeMail
      stripeChargeEnable
      stripeLastFourDigits
      stripePayoutEnable
      stripeConnectedAccount
      providerImage {
        providerId
        url
      }
      providerComment {
        score
      }
      service {
        id
        name
        description
        partyPlanner
        allCities
        insertedAt
        category
        serviceComment {
          score
        }
        serviceImage {
          url
        }
      }
    }
  }
`;

export const GET_PROVIDER_PRODUCTS = gql`
  query getProviderServices($providerId: Int!) {
    provider(id: $providerId) {
      service {
        id
        name
        category
        insertedAt
        serviceComment {
          score
          comment
        }
        serviceImage {
          url
        }
      }
    }
    providerPackages(providerId: $providerId, isProvider: true) {
      id
      name
      type
      insertedAt
      packageImages {
        url
      }
    }
  }
`;

export const GET_PROVIDERS_WITHOUT_CURSOR = gql`
  query getProvidersWithoutCursor(
    $name: String
    $state: String
    $city: String
    $perPage: Int!
  ) {
    providers(name: $name, state: $state, city: $city, perPage: $perPage) {
      cursor
      providers {
        id
        name
        city
        state
        photoUrl
        providerComment {
          score
          comment
        }
      }
    }
    countProviders(name: $name, state: $state, city: $city) {
      providersCount
      providersCitiesCount
    }
  }
`;

export const GET_PROVIDERS_DISTINCT_LOCATIONS = gql`
  query getDistinctProvidersLocations(
    $name: String
    $state: String
    $city: String
  ) {
    distinctProvidersLocations(name: $name, state: $state, city: $city) {
      states
      cities
    }
  }
`;

export const GET_PROVIDERS_WITH_CURSOR = gql`
  query getProvidersWithCursor(
    $name: String
    $state: String
    $city: String
    $perPage: Int!
    $cursor: String!
  ) {
    providers(
      name: $name
      state: $state
      city: $city
      perPage: $perPage
      cursor: $cursor
    ) {
      cursor
      providers {
        id
        name
        city
        state
        photoUrl
        providerComment {
          score
          comment
        }
      }
    }
    countProviders(name: $name, state: $state, city: $city) {
      providersCount
      providersCitiesCount
    }
  }
`;

export const GET_PROVIDER_CALENDAR_EVENTS = gql`
  query providerCalendarEvents($providerId: Int!) {
    providerCalendarEvents(providerId: $providerId) {
      id
      isExternalEvent
      name
      clientName
      serviceId
      packageId
      packageReservationId
      startTime
      endTime
    }
  }
`;

// SERVICE QUERIES

export const GET_SERVICE_INFO = gql`
  query getServiceInfo($id: Int!) {
    service(id: $id) {
      allCities
      category
      name
      city
      state
      priceRange
      minPeople
      maxPeople
      serviceImage {
        url
      }
      serviceTag {
        tag
      }
      paymentAdvance
      paymentDeadline
      cancellationDeadline
      reimbursement
      description
      partyPlanner
    }
  }
`;

export const GET_SERVICE_IMAGES = gql`
  query getServiceImages($serviceId: Int!) {
    serviceImages(serviceId: $serviceId) {
      base64
      name
    }
  }
`;

export const GET_SERVICE_INFO_WITH_SCORE = gql`
  query getServices($id: Int!) {
    service(id: $id) {
      provider {
        name
      }
      minPeople
      maxPeople
      category
      city
      category
      description
      name
      state
      partyPlanner
      allCities
      providerId
      paymentAdvance
      paymentDeadline
      cancellationDeadline
      reimbursement
      serviceImage {
        url
      }
      serviceComment {
        score
      }
      serviceTag {
        tag
      }
    }
  }
`;

export const GET_SERVICES_WITHOUT_CURSOR = gql`
  query getServicesWithoutCursor(
    $category: String
    $name: String
    $state: String
    $city: String
    $priceRange: Int
    $perPage: Int!
  ) {
    services(
      category: $category
      name: $name
      state: $state
      city: $city
      priceRange: $priceRange
      perPage: $perPage
    ) {
      cursor
      services {
        service {
          id
          name
          category
          serviceComment {
            id
            score
            comment
            serviceId
          }
          serviceImage {
            url
          }
          priceRange
        }
        provider {
          name
        }
      }
    }
    countServices(
      category: $category
      name: $name
      state: $state
      city: $city
      priceRange: $priceRange
    ) {
      servicesCount
      servicesCitiesCount
    }
  }
`;

export const GET_SERVICES_DISTINCT_CATEGORIES = gql`
  query getDistinctServicesCategories(
    $name: String
    $state: String
    $city: String
    $priceRange: Int
  ) {
    distinctServicesCategories(
      name: $name
      state: $state
      city: $city
      priceRange: $priceRange
    ) {
      categories
    }
  }
`;

export const GET_SERVICES_DISTINCT_LOCATIONS = gql`
  query getDistinctServicesLocations(
    $category: String
    $name: String
    $state: String
    $city: String
    $priceRange: Int
  ) {
    distinctServicesLocations(
      category: $category
      name: $name
      state: $state
      city: $city
      priceRange: $priceRange
    ) {
      states
      cities
    }
  }
`;

export const GET_SERVICES_WITH_CURSOR = gql`
  query getServicesWithCursor(
    $category: String
    $name: String
    $state: String
    $city: String
    $priceRange: Int
    $perPage: Int!
    $cursor: String!
  ) {
    services(
      category: $category
      name: $name
      state: $state
      city: $city
      priceRange: $priceRange
      perPage: $perPage
      cursor: $cursor
    ) {
      cursor
      services {
        service {
          id
          name
          state
          city
          category
          serviceComment {
            id
            score
            comment
            serviceId
          }
          serviceImage {
            url
          }
          priceRange
        }
        provider {
          name
        }
      }
    }
    countServices(
      category: $category
      name: $name
      state: $state
      city: $city
      priceRange: $priceRange
    ) {
      servicesCount
      servicesCitiesCount
    }
  }
`;

// PACKAGES QUERIES

export const GET_PACKAGES_WITHOUT_CURSOR = gql`
  query getPackagesWithoutCursor($perPage: Int!) {
    packages(perPage: $perPage) {
      packages {
        provider {
          name
        }
        package {
          id
          name
          packageImages {
            id
            url
          }
        }
      }
    }
    countPackages {
      packagesCount
      packagesCitiesCount
    }
  }
`;

export const GET_PACKAGES_WITH_CURSOR = gql`
  query getPackagesWithCursor($perPage: Int!, $cursor: String!) {
    packages(perPage: $perPage, cursor: $cursor) {
      cursor
      packages {
        provider {
          name
        }
        package {
          id
          name
          packageImages {
            id
            url
          }
        }
      }
    }
    countPackages {
      packagesCount
      packagesCitiesCount
    }
  }
`;

// QUOTATION REQUEST QUERIES

export const GET_QUOTATION_DATA = gql`
  query getQuotationInfo($quotationId: Int!, $clientId: Int!, $route: String!) {
    quotation(quotationId: $quotationId, clientId: $clientId, route: $route) {
      provider {
        id
        name
        email
        phone
        street
        externalNumber
        internalNumber
        zipCode
        district
        city
        state
      }
      service {
        category
        name
        id
        paymentAdvance
        paymentDeadline
      }
      quotationRequest {
        category
        eventType
        requesterCity
        requesterName
        requesterEmail
        requesterState
        requesterPhone
        status
        clientComment
        eventDate
        peopleQuantity
        eventAddress
        eventState
        eventCity
        eventDate
        providerComment
        totalPayment
        quotationUrl
        contractUrl
        insertedAt
        paymentAdvance
        paymentDeadline
        paymentProvider
        paymentZaturna
        eventType
        validUntil
        insertedAt
        requestExtraDocument {
          url
        }
        rejectedRequest {
          rejectMotive
        }
      }
      user {
        name
        preferedName
        email
        phone
        state
        city
      }
    }
  }
`;

export const GET_QUOTATION_PAYMENT_DATA = gql`
  query getQuotationPaymentData(
    $quotationId: Int!
    $clientId: Int!
    $route: String!
  ) {
    quotation(quotationId: $quotationId, clientId: $clientId, route: $route) {
      provider {
        name
      }
      service {
        id
        name
      }
      quotationRequest {
        id
        status
        totalPayment
        quotationUrl
        paymentDeadline
        paymentAdvance
        validUntil
        reservation {
          payment {
            total
            date
            paid
          }
          id
          totalPaid
          status
        }
      }
    }
  }
`;

export const GET_USER_QUOTATION_REQUESTS = gql`
  query getUserQuotationRequests($userId: Int!) {
    userQuotations(userId: $userId) {
      quotationRequest {
        id
        insertedAt
        status
        eventType
      }
      event {
        id
      }
      service {
        id
        name
        serviceComment {
          score
        }
        serviceImage {
          url
        }
      }
      provider {
        name
      }
    }
  }
`;

export const GET_PROVIDER_QUOTATION_REQUESTS = gql`
  query getProviderQuotationRequests($providerId: Int!) {
    providerQuotations(providerId: $providerId) {
      quotationRequest {
        id
        eventType
        requesterEmail
        requesterName
        requesterPhone
        status
        eventDate
      }
      service {
        name
      }
    }
  }
`;

// RESERVATIONS QUERIES

export const GET_RESERVATION_DETAILS = gql`
  query getReservationData($id: Int!, $clientId: Int!, $route: String!) {
    reservation(reservationId: $id, clientId: $clientId, route: $route) {
      status
      id
      payment {
        paid
        total
        date
      }
      totalPaid
      quotationRequest {
        id
        category
        eventType
        requesterCity
        requesterName
        requesterEmail
        requesterState
        requesterPhone
        status
        clientComment
        eventDate
        peopleQuantity
        eventAddress
        eventCity
        eventDate
        totalPayment
        paymentAdvance
        paymentDeadline
        paymentProvider
        paymentZaturna
        eventType
        validUntil
        quotationUrl
        eventService {
          event {
            user {
              preferedName
            }
          }
          service {
            id
            name
            category
            provider {
              id
              name
              email
              phone
              street
              externalNumber
              internalNumber
              zipCode
              district
              city
              state
              service {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_USER_RESERVATIONS = gql`
  query getUserResevations($userId: Int!) {
    userReservations(userId: $userId) {
      event {
        date
      }
      quotationRequest {
        status
        totalPayment
        eventType
      }
      eventService {
        eventId
        id
      }
      provider {
        name
      }
      reservation {
        status
        totalPaid
        id
        insertedAt
      }
      service {
        name
        serviceImage {
          url
        }
      }
    }
    userPackageReservations(userId: $userId) {
      id
      eventDate
      eventName
      status
      totalPayment
      totalPaid
      packageName
      providerName
      insertedAt
      package {
        packageImages {
          url
        }
      }
    }
  }
`;

export const GET_PROVIDER_RESERVATIONS = gql`
  query getProviderReservations($providerId: Int!) {
    providerReservations(providerId: $providerId) {
      name
      service {
        id
        name
        serviceQuotationRequests {
          status
          eventDate
          requesterName
          requesterPhone
          requesterEmail
          reservation {
            id
          }
        }
      }
    }
    providerPackageReservations(providerId: $providerId) {
      id
      packageName
      status
      eventDate
      userName
      userPhone
      userEmail
    }
  }
`;

// PACKAGE RESERVATIONS QUERIES
export const GET_PACKAGE_RESERVATION = gql`
  query getPackageReservation(
    $clientId: Int!
    $packageReservationId: Int!
    $route: String!
  ) {
    packageReservation(
      clientId: $clientId
      packageReservationId: $packageReservationId
      route: $route
    ) {
      additionalComment
      cancellationDeadline
      corkageCost
      eventDate
      eventEndTime
      eventName
      eventStartTime
      extraAdultCost
      extraAdults
      extraHourCost
      extraHours
      extraKidCost
      extraKids
      id
      packageAddress
      packageAdultsQuantity
      packageCity
      packageCost
      packageKidsQuantity
      packageName
      packageState
      paymentDeadline
      providerCity
      providerDistrict
      providerEmail
      providerName
      providerPayment
      providerPhone
      providerRfc
      providerState
      providerStreet
      providerExternalNumber
      providerInternalNumber
      providerZipCode
      reimbursement
      package {
        id
        providerId
        cancellationDeadline
        paymentAdvance
        description
        corkage
        extraHour
        paymentDeadline
        adultsQuantity
        kidsQuantity
        packageItems {
          id
          name
          quantity
        }
      }
      reservationDishes {
        description
        name
        id
        isForAdult
        packageReservationId
        quantity
      }
      reservationItems {
        id
        description
        name
        quantity
      }
      reservationPayments {
        id
        date
        total
      }
      reservationSupplementaryItems {
        id
        name
        price
        quantity
        unit
      }
      status
      totalPaid
      totalPayment
      userAddress
      userCity
      userEmail
      userId
      userName
      userPhone
      userState
      userZipCode
      zaturnaPayment
    }
  }
`;

// EVENT QUERIES

export const GET_USER_EVENTS_WITH_PAYMENTS = gql`
  query getUserEventsWithPayments($userId: Int!) {
    userEventsCompleteData(userId: $userId) {
      event {
        eventService {
          quotationRequest {
            id
            status
            eventDate
            paymentDeadline
            totalPayment
            reservation {
              id
              totalPaid
              status
              payment {
                date
                paid
                total
              }
            }
          }
        }
      }
      service {
        name
      }
    }
    userPackageReservations(userId: $userId) {
      packageName
      id
      status
      eventDate
      paymentDeadline
      totalPayment
      totalPaid
      reservationPayments {
        date
        paid
        total
      }
    }
  }
`;

// STRIPE QUERIES

export const GET_STRIPE_CHECKOUT_SESSION = gql`
  query getStripeCheckoutSession(
    $payment: Float!
    $serviceId: Int!
    $successUrl: String!
    $cancelUrl: String!
    $customerEmail: String!
    $paymentMethod: String!
  ) {
    getStripeCheckoutSession(
      payment: $payment
      serviceId: $serviceId
      successUrl: $successUrl
      cancelUrl: $cancelUrl
      customerEmail: $customerEmail
      paymentMethod: $paymentMethod
    ) {
      url
      id
    }
  }
`;

export const GET_STRIPE_URL_REGISTER = gql`
  query getStripeUrlRegister(
    $stripe_id: String!
    $return_url: String!
    $refresh_url: String!
  ) {
    getStripeUrlRegister(
      id: $stripe_id
      return_url: $return_url
      refresh_url: $refresh_url
    ) {
      url
    }
  }
`;

// PACKAGES QUERIES

export const GET_PACKAGE = gql`
  query getPackage($packageId: Int!, $isProvider: Boolean) {
    package(packageId: $packageId, isProvider: $isProvider) {
      id
      address
      corkage
      corkagePrice
      cancellationDeadline
      description
      extraHour
      extraHourPrice
      extraCapacity
      paymentAdvance
      providerId
      paymentDeadline
      kidsQuantity
      kidExtraPrice
      adultsQuantity
      adultExtraPrice
      city
      state
      name
      reimbursement
      type
      provider {
        name
      }
      packagePrices {
        dayOfWeek
        id
        packageId
        price
      }
      packageDishes {
        description
        id
        isForAdult
        name
        packageId
      }
      packageImages {
        id
        packageId
        url
      }
      packageItems {
        description
        id
        name
        packageId
        quantity
      }
      packageSupplementaryItems {
        name
        description
        id
        packageId
        price
        unit
      }
      packageSchedule {
        timeStart
        timeEnd
        packageId
      }
    }
  }
`;

export const GET_AVAILABLE_PACKAGE_SCHEDULE = gql`
  query availablePackageSchedule($packageId: Int!, $date: String!) {
    availablePackageSchedule(packageId: $packageId, date: $date) {
      id
      isAvailable
      timeEnd
      timeStart
    }
  }
`;

// OTHER QUERIES

export const GET_CATEGORIES = gql`
  query getCategories {
    categories {
      id
      name
    }
  }
`;

export const GET_QUESTIONS = gql`
  query getQuestions {
    zaturnaQuestions {
      id
      answer
      question
      type
    }
  }
`;
